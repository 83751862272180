import styled from '@emotion/styled'
import { Color } from '@mathison-inc/components'
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Modal,
  Snackbar,
  SnackbarProps,
  Typography
} from '@mui/material'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import React, { useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const DownloadButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#F2F3F3',
  textDecoration: 'none',
  padding: '12px 16px',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'IBM Plex Sans',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    textDecoration: 'none'
  }
}))

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

interface IProps {
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const DashboardDownloadButton = ({ setSnackbarOpen }: IProps) => {
  const scrollRef = useRef<number | null>(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [middleSectionLoaded, setMiddleSectionLoaded] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const captureAndGeneratePDF = () => {
    const date = new Date().toDateString().slice(4).split(' ').join('_')
    const employeeRepresentation: any = document.getElementById(
      'employee-representation'
    )

    if (!employeeRepresentation) {
      // eslint-disable-next-line no-console
      console.error('Cannot find Employee Representation Dashboard.')
      return
    }

    html2canvas(employeeRepresentation)
      .then(canvas => {
        const img = canvas.toDataURL('image/png')
        const pdf = new JsPDF('p', 'px')

        pdf.internal.pageSize.width = employeeRepresentation.offsetWidth
        pdf.internal.pageSize.height = employeeRepresentation.offsetHeight

        pdf.addImage(
          img,
          'PNG',
          10,
          10,
          employeeRepresentation.offsetWidth - 40,
          employeeRepresentation.offsetHeight - 40
        )
        pdf.save(`ER_Dashboard_${date}.pdf`)
        setSnackbarOpen(true)
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error while generating PDF:', error)
      })
      .finally(() => {
        setIsDownloading(false)
        setOpenModal(false)
        window.scrollTo(0, 0)
      })
  }

  const scrollToBottom = () => {
    return new Promise<void>(resolve => {
      const scrollStep = 150
      let scrollY = 0

      const animateScroll = () => {
        if (scrollY >= document.body.scrollHeight - window.innerHeight) {
          resolve()
          return
        }

        scrollY += scrollStep
        scrollRef.current = requestAnimationFrame(() => {
          window.scrollTo(0, scrollY)
          animateScroll()
        })
      }

      animateScroll()
    })
  }

  const handleDownload = async () => {
    if (!isDownloading) {
      setIsDownloading(true)
      setMiddleSectionLoaded(false)
      setOpenModal(true)
      await scrollToBottom()
      setMiddleSectionLoaded(true)
      setIsDownloading(false)
    }
  }

  const handleConfirmDownload = () => {
    setIsDownloading(true)
    if (middleSectionLoaded) {
      captureAndGeneratePDF()
    } else {
      // eslint-disable-next-line no-console
      console.error('Middle section is not loaded yet.')
    }
  }

  const handleCancel = () => {
    if (!isDownloading) {
      clearInterval(scrollRef.current!)
      setOpenModal(false)
      cancelAnimationFrame(scrollRef.current as number)
    }
  }

  useEffect(() => {
    const enableButtonTimeout = setTimeout(() => {
      setIsButtonDisabled(false)
    }, 3000)

    return () => {
      clearTimeout(enableButtonTimeout)
    }
  }, [])

  return (
    <Box>
      <DownloadButton
        onClick={handleDownload}
        disabled={isDownloading || isButtonDisabled}
      >
        Download as PDF
      </DownloadButton>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '600px',
            padding: '64px 24px 24px 24px',
            backgroundColor: Color.white,
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px'
          }}
        >
          <IconButton
            disableRipple
            onClick={() => {
              setOpenModal(false)
            }}
            sx={{
              position: 'absolute',
              top: 24,
              right: 24,
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <CloseIcon sx={{ color: 'black', fontSize: '20px' }} />
          </IconButton>
          <Typography variant='h3' sx={{ marginBottom: '26px' }}>
            Preparing your PDF...This download will include all graphs.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant='contained'
              disableRipple
              onClick={handleConfirmDownload}
              disabled={!middleSectionLoaded || isDownloading}
              sx={{
                backgroundColor: Color.black,
                color: Color.white,
                '&:hover': {
                  backgroundColor: Color.black
                }
              }}
            >
              Start Download
            </Button>
            <Button
              variant='contained'
              disableRipple
              onClick={() => {
                handleCancel()
              }}
              sx={{
                backgroundColor: Color.grey100,
                color: Color.black,
                marginRight: '16px',
                '&:hover': {
                  backgroundColor: Color.grey100
                }
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
