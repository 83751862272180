import React, { useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Grid, Typography } from '@mui/material'

const Feedback = () => {
  const [message] = useState('')
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
      <InfoOutlinedIcon />
      <a
        href={`mailto:support@mathison.io?subject=Mathison Product Feedback&body=${encodeURIComponent(
          message
        )}`}
      >
        <Typography
          sx={{
            cursor: 'pointer',
            marginLeft: '8px',
            textDecoration: 'underline',
            fontWeight: 700
          }}
        >
          Share Feedback
        </Typography>
      </a>
    </Grid>
  )
}

export default Feedback
