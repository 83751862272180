import { HrisRawData } from '../models'

export const MOCK_DATA_V1: HrisRawData[] = [
  {
    employeeId: 1,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 1,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 1,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DIVISION'
  },
  {
    employeeId: 2,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 45,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 3,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'NON-BINARY',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 3,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DIVISION'
  },
  {
    employeeId: 4,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 35,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 5,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 6,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 7,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 8,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 9,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 43,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 10,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 11,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 12,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 13,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 14,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 33,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 15,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 16,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 17,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 18,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 31,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 19,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 20,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 21,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 22,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 23,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 24,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 33,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 25,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 26,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 27,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 28,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 29,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 30,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 31,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 48,
    employmentType: 'CONTRACTOR',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 32,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 33,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 34,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 35,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 36,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 37,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 38,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 30,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 39,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 40,
    employmentType: 'CONTRACTOR',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 40,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 41,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 42,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 43,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 44,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 45,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 46,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 47,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 48,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 49,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 50,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 52,
    employmentType: 'FULL_TIME',
    ethnicity: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 51,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 56,
    employmentType: 'PART_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 52,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 63,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 53,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 54,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 55,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 43,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 56,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 57,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 58,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 59,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 37,
    employmentType: 'PART_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 60,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 29,
    employmentType: 'CONTRACTOR',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 61,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 25,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 62,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 31,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 63,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 64,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 65,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 21,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 66,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 67,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 68,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 69,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 70,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 71,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 72,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 45,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 73,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 44,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 74,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 75,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 30,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 76,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 77,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 78,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 79,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 80,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 81,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 82,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 30,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'NON-BINARY',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 83,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 84,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 85,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 86,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 87,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 88,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 89,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 90,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 91,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 92,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 93,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 30,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 94,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 95,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 96,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 97,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 98,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 99,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 43,
    employmentType: 'CONTRACTOR',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 100,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 101,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 102,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 103,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 104,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 105,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 25,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 106,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 31,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 107,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 108,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 109,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 21,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 110,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 111,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 36,
    employmentType: 'PART_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 112,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 113,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 114,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 115,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 116,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 117,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 118,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 119,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 120,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 121,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 122,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 35,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 123,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 124,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 125,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 126,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 127,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'NON-BINARY',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 128,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 129,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 130,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 27,
    employmentType: 'CONTRACTOR',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 131,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 26,
    employmentType: 'PART_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 132,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'NON-BINARY',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 133,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 134,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 135,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 136,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 137,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 38,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 138,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 139,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 140,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 35,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 141,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'CONTRACTOR',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 142,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 143,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 144,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 145,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 146,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 147,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 27,
    employmentType: 'PART_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 148,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 149,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 150,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Engineering & Product',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 151,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 152,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 153,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 154,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 155,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 156,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 157,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 158,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'NON-BINARY',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 159,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 49,
    employmentType: 'CONTRACTOR',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 160,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 161,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 52,
    employmentType: 'PART_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 162,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 56,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 163,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 63,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 164,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 165,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 166,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 167,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 168,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 52,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 169,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 56,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 170,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 63,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 171,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 172,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 173,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 43,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 174,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 175,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 176,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 177,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 178,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 179,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 25,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 180,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 48,
    employmentType: 'PART_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 181,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 37,
    employmentType: 'CONTRACTOR',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 182,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 183,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'NON-BINARY',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 184,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 185,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 186,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 187,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 30,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 188,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 189,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 190,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 191,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 192,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 42,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 193,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 36,
    employmentType: 'PART_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 194,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 43,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 195,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 196,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 197,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 198,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 199,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 33,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 200,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Sales',
    age: 40,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 201,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 202,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 203,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 204,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 205,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 206,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 207,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 208,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'FEMALE',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 209,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 40,
    employmentType: 'CONTRACTOR',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 210,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 52,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 211,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 56,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 212,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Finance',
    age: 51,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 213,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 39,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 214,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 215,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 43,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 216,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 217,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 49,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 218,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 34,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 219,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Operations',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 220,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 29,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'MALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 221,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 25,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 222,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 31,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 223,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 42,
    employmentType: 'PART_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 224,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 23,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 225,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 21,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 226,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 27,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 227,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 26,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 228,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Client Services',
    age: 41,
    employmentType: 'CONTRACTOR',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 229,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 28,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 1,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 230,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 41,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'NON-BINARY',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 231,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 48,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 232,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 47,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 233,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 45,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 234,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 32,
    employmentType: 'PART_TIME',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'NON-BINARY',
    tenureYears: 8,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 235,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'ASIAN_OR_INDIAN_SUBCONTINENT',
    gender: 'FEMALE',
    tenureYears: 2,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 236,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 35,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'FEMALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 237,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Human Resources',
    age: 32,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 6,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 238,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 32,
    employmentType: 'CONTRACTOR',
    ethnicity: 'WHITE',
    gender: 'FEMALE',
    tenureYears: 7,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 239,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 37,
    employmentType: 'FULL_TIME',
    ethnicity: 'BLACK_OR_AFRICAN_AMERICAN',
    gender: 'FEMALE',
    tenureYears: 5,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 240,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 31,
    employmentType: 'FULL_TIME',
    ethnicity: 'WHITE',
    gender: 'MALE',
    tenureYears: 4,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 241,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 36,
    employmentType: 'FULL_TIME',
    ethnicity: 'TWO_OR_MORE_RACES',
    gender: 'MALE',
    tenureYears: 3,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  },
  {
    employeeId: 242,
    employerId: 'test_ep_id',
    employmentStatus: 'ACTIVE',
    department: 'Marketing',
    age: 23,
    employmentType: 'CONTRACTOR',
    ethnicity: 'HISPANIC_OR_LATINO',
    gender: 'MALE',
    tenureYears: 0,
    veteranStatus: '',
    disabledStatus: '',
    lastSyncedMathison: '2024-10-29T19:45:17Z',
    integration: 'Rippling',
    groupType: 'DEPARTMENT'
  }
]
