import React, { useMemo } from 'react'
import { MicroServices } from 'models/microservice'

export const MicroServiceContext = React.createContext<MicroServices>({
  children: undefined,
  maestroClient: undefined,
  currentAccount: undefined,
  isFlagEnabled: () => undefined,
  route: { to: undefined, from: undefined },
  displayNotification: () => {}
})

export const MicroServiceProvider = ({
  children,
  maestroClient,
  currentAccount,
  isFlagEnabled,
  route,
  displayNotification
}: MicroServices): JSX.Element => {
  const value = useMemo(
    () => ({
      children,
      maestroClient,
      currentAccount,
      isFlagEnabled,
      route,
      displayNotification
    }),
    [
      children,
      maestroClient,
      currentAccount,
      isFlagEnabled,
      route,
      displayNotification
    ]
  )

  return (
    <MicroServiceContext.Provider value={value}>
      {children}
    </MicroServiceContext.Provider>
  )
}
