/* eslint-disable no-console */
import React from 'react'
import { AgeGraphData, Record } from 'models'
import { BarChart } from '@mui/x-charts/BarChart'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { Box, Typography, createTheme } from '@mui/material'
import { getAgeBarData } from 'hooks/dataAggregation'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536
    }
  }
})

const chartSetting = {
  xAxis: [
    {
      label: 'percentage (%)'
    }
  ],
  height: 400,
  sx: {
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-10px)'
    }
  }
}

const valueFormatter = (value: number | null) => `${value}%`

interface IProps {
  data: Record[]
}

const AgeBarChart = ({ data }: IProps) => {
  // Get Age Group Percentages Graph Data
  const ageGroupGraphData: AgeGraphData[] = getAgeBarData(data)
  return (
    <Box
      sx={{
        borderRadius: '10px',
        border: '1px solid #f1f1f1',
        backgroundColor: '#FFFFFF',
        padding: '20px 20px 20px 80px',
        [theme.breakpoints.down('lg')]: {
          padding: '20px'
        }
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          textAlign: 'left',
          marginleft: '-50px',
          [theme.breakpoints.down('lg')]: {
            fontSize: '16px',
            marginLeft: 0
          }
        }}
      >
        Age Groups
      </Typography>
      <BarChart
        colors={['#5f71e5']}
        dataset={ageGroupGraphData}
        slotProps={{
          legend: {
            hidden: true
          }
        }}
        yAxis={[
          { scaleType: 'band', dataKey: 'ageGroup', label: 'Age Group (years)' }
        ]}
        series={[
          { dataKey: 'percentage', label: 'Percentage', valueFormatter }
        ]}
        layout='horizontal'
        {...chartSetting}
      />
    </Box>
  )
}
export default AgeBarChart
