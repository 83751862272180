import { SurveyCardType, SalesCardType } from 'models'

export const SURVEY_CARD_DATA: SurveyCardType = {
  url: 'https://www.datocms-assets.com/54533/1691423605-isolation_mode.png',
  copy: "Consider deploying Mathison's free Employee Survey for a more holistic and inclusive view of your workforce.",
  buttonText: 'Learn more'
}

export const SALES_CARD_DATA: SalesCardType = {
  url: 'https://www.datocms-assets.com/54533/1683645761-isolation_mode.png'
}
