/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import dayjs from 'dayjs'
import {
  DataMap,
  ActiveEmployees,
  HrisData,
  HrisRawData,
  SingleStat,
  PieChartDataPoint,
  BarGraphData,
  BarGraphDataPoint,
  GroupedRecord,
  Record,
  AgeGraphData,
  GroupedAgeRecord
} from '../models'

enum AgeGroup {
  NO_VALUE = 'No Value',
  UNDER_TWENTY = '0 - 20 YRS',
  TWENTY_TO_TWENTY_FOUR = '20 - 24 YRS',
  TWENTY_FIVE_TO_TWENTY_NINE = '25 - 29 YRS',
  THIRTY_TO_THIRTY_FOUR = '30 - 34 YRS',
  THIRTY_FIVE_TO_THIRTY_NINE = '35 - 39 YRS',
  FORTY_TO_FORTY_FOUR = '40 - 44 YRS',
  FORTY_FIVE_TO_FORTY_NINE = '45 - 49 YRS',
  FIFTY_TO_FIFTY_FOUR = '50 - 54 YRS',
  FIFTY_FIVE_TO_FIFTY_NINE = '55 - 59 YRS',
  SIXTY_PLUS = '60+ YRS'
}

enum Ethnicity {
  ASIAN_OR_INDIAN_SUBCONTINENT = 'Asian or Asian American',
  BLACK_OR_AFRICAN_AMERICAN = 'Black',
  HISPANIC_OR_LATINO = 'Hispanic or Latino',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'Indigenous',
  WHITE = 'White',
  TWO_OR_MORE_RACES = 'Other'
}

const getAgeGroup = (age: number): string => {
  if (age <= 0) return AgeGroup.NO_VALUE
  if (age < 20) return AgeGroup.UNDER_TWENTY
  if (age < 25) return AgeGroup.TWENTY_TO_TWENTY_FOUR
  if (age < 30) return AgeGroup.TWENTY_FIVE_TO_TWENTY_NINE
  if (age < 35) return AgeGroup.THIRTY_TO_THIRTY_FOUR
  if (age < 40) return AgeGroup.THIRTY_FIVE_TO_THIRTY_NINE
  if (age < 45) return AgeGroup.FORTY_TO_FORTY_FOUR
  if (age < 50) return AgeGroup.FORTY_FIVE_TO_FORTY_NINE
  if (age < 55) return AgeGroup.FIFTY_TO_FIFTY_FOUR
  if (age < 60) return AgeGroup.FIFTY_FIVE_TO_FIFTY_NINE
  return AgeGroup.SIXTY_PLUS
}

const getEthnicity = (ethnicity: string | null | undefined): string | null => {
  if (!ethnicity) return null
  return Ethnicity[ethnicity as keyof typeof Ethnicity] || 'Other'
}

const formatTenure = (tenure: number): number => {
  if (tenure < 0) return 0
  return tenure
}

const formatGender = (gender: string | null | undefined): string => {
  if (!gender) return 'No Value'
  const formattedGender = gender.toLowerCase()
  return formattedGender.charAt(0).toUpperCase() + formattedGender.slice(1)
}

const filterAndReplaceDuplicates = (arr: HrisRawData[]): HrisRawData[] => {
  const specificValue: string = 'ALL'
  const uniqueObjects: { [key: string]: HrisRawData } = {}
  // Add all objects with groupType as department to the result array (will modify this array as we know more about the values coming back).
  const includedKeys: (string | null)[] = [
    'DEPARTMENT',
    'Department',
    'department',
    'DEPT',
    'Dept',
    'dept',
    null
  ]
  // Filter the starting array to only include objects with groupType as department.
  const filteredArr: HrisRawData[] = arr.filter((obj: HrisRawData): boolean =>
    includedKeys.includes(obj.groupType)
  )

  filteredArr.forEach((obj: HrisRawData): void => {
    if (obj.department !== specificValue) {
      // For non-ALL values, add the object to the uniqueObjects object with the id as the key
      uniqueObjects[obj.employeeId] = obj
    } else if (!uniqueObjects[obj.employeeId]) {
      // If the object has the specific value and there's no previous object with
      // the same id having the specific value, add the object to uniqueObjects.
      uniqueObjects[obj.employeeId] = obj
    }
  })

  const uniqueObjectsArr: HrisRawData[] = Object.values(uniqueObjects)

  // Change the department value "ALL" to "No Value" since the employee did not have a department.
  return uniqueObjectsArr.map((obj: HrisRawData) => {
    if (obj.department === 'ALL') {
      return { ...obj, department: 'No Value' }
    }
    return obj
  })
}

export const formatData = (
  rawData: HrisRawData[] | undefined | null
): HrisData => {
  if (!rawData || !rawData.length)
    return {
      employerId: '',
      lastSyncedMathison: '',
      integration: '',
      employees: []
    }

  const uiData: HrisData = {
    employerId: rawData[0].employerId,
    lastSyncedMathison: rawData[0].lastSyncedMathison,
    integration: rawData[0].integration,
    employees: []
  }

  // Remove duplicates for now until we finalize on how to model department data
  const uniqueRawData: HrisRawData[] = filterAndReplaceDuplicates(rawData)

  // Add each employee data to the uniqueEmployees array
  uniqueRawData.forEach((employee: HrisRawData, index: number) => {
    uiData.employees[index] = {
      employeeId: employee.employeeId.toString(),
      employmentStatus: employee.employmentStatus || 'Inactive',
      department: employee.department || 'No Value',
      age: employee.age || 0,
      ageGroup: getAgeGroup(employee.age || 0),
      ethnicity: getEthnicity(employee.ethnicity) || 'No Value',
      gender: formatGender(employee.gender),
      tenureYears: formatTenure(employee.tenureYears || 0),
      veteranStatus: employee.veteranStatus || 'No Value',
      disabledStatus: employee.disabledStatus || 'No Value',
      groupType: employee.groupType || 'No Value'
    } as Record
  })
  return uiData
}

export const getLastSyncedDate = (lastSynced: string): string => {
  if (!lastSynced) return ''
  return dayjs(lastSynced).format('MMMM D, YYYY')
}

export const createDepartmentDataSet = (data: DataMap[]): DataMap[] => {
  return data.filter(employee => employee.department !== 'All')
}

export const getActiveEmployees = (data: DataMap[]): ActiveEmployees => {
  const dataMap: DataMap[] = data.filter(
    employee => employee.employmentStatus === 'ACTIVE'
  )
  const activeEmployees: SingleStat = {
    title: 'Active Employees',
    value: dataMap.length
  }

  return {
    dataMap,
    activeEmployees
  } as ActiveEmployees
}

export const getAverageAge = (data: DataMap[]): SingleStat => {
  const ageArray: any[] = []
  data.forEach(employee => {
    ageArray.push(employee?.age)
  })
  const averageAge: number = ageArray.reduce((a, b) => a + b) / ageArray.length
  return {
    title: 'Average Age',
    value: parseFloat(averageAge.toFixed(2))
  }
}

export const getAverageTenure = (data: DataMap[]): SingleStat => {
  const tenureArray: any[] = []
  data.forEach(employee => {
    tenureArray.push(employee?.tenureYears)
  })
  const averageTenure: number =
    tenureArray.reduce((a, b) => a + b) / tenureArray.length
  return {
    title: 'Average Tenure',
    value: parseFloat(averageTenure.toFixed(2))
  }
}

export const getEthnicityDataForPieChart = (
  data: DataMap[]
): PieChartDataPoint[] => {
  const pieChartData: PieChartDataPoint[] = []

  // get White employees
  const whiteEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'White'
  )
  const whiteEmployeeData: PieChartDataPoint = {
    id: 1,
    value: whiteEmployees.length,
    label: 'White'
  }
  pieChartData.push(whiteEmployeeData)

  // get Black employees
  const blackEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'Black'
  )
  const blackEmployeeData: PieChartDataPoint = {
    id: 2,
    value: blackEmployees.length,
    label: 'Black'
  }
  pieChartData.push(blackEmployeeData)

  // get Asian or Asian American employees
  const asianEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'Asian or Asian American'
  )
  const asianEmployeeData: PieChartDataPoint = {
    id: 3,
    value: asianEmployees.length,
    label: 'Asians'
  }
  pieChartData.push(asianEmployeeData)

  // get Indigenous employees
  const indigenousEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'Indigenous'
  )
  const indigenousEmployeeData: PieChartDataPoint = {
    id: 4,
    value: indigenousEmployees.length,
    label: 'Indigenous'
  }
  pieChartData.push(indigenousEmployeeData)

  // get Hispanic or Latino employees
  const latineEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'Hispanic or Latino'
  )
  const latineEmployeeData: PieChartDataPoint = {
    id: 5,
    value: latineEmployees.length,
    label: 'Latine'
  }
  pieChartData.push(latineEmployeeData)

  // get Other employees
  const otherEmployees: DataMap[] = data.filter(
    employee => employee.ethnicity === 'Other'
  )
  const otherEmployeeData: PieChartDataPoint = {
    id: 6,
    value: otherEmployees.length,
    label: 'Other'
  }
  pieChartData.push(otherEmployeeData)

  return pieChartData
}

export const getGenderIdentityDataForPieChart = (
  data: DataMap[]
): PieChartDataPoint[] => {
  const pieChartData: PieChartDataPoint[] = []

  // Get Female Employees
  const femaleEmployees: DataMap[] = data.filter(
    employee => employee.gender === 'Female'
  )
  const femaleEmployeeData: PieChartDataPoint = {
    id: 1,
    value: femaleEmployees.length,
    label: 'Female'
  }
  pieChartData.push(femaleEmployeeData)

  // Get Male Employees
  const maleEmployees: DataMap[] = data.filter(
    employee => employee.gender === 'Male'
  )
  const maleEmployeeData: PieChartDataPoint = {
    id: 2,
    value: maleEmployees.length,
    label: 'Male'
  }
  pieChartData.push(maleEmployeeData)

  // Get Non-binary Employees
  const nonBinaryEmployees: DataMap[] = data.filter(
    employee => employee.gender === 'Non-binary'
  )
  const nonBinaryEmployeeData: PieChartDataPoint = {
    id: 3,
    value: nonBinaryEmployees.length,
    label: 'Non-binary'
  }
  pieChartData.push(nonBinaryEmployeeData)

  // Get No Value Employees
  const noValueEmployees: DataMap[] = data.filter(
    employee => employee.gender === 'No Value'
  )
  const noValueEmployeeData: PieChartDataPoint = {
    id: 4,
    value: noValueEmployees.length,
    label: 'No Value'
  }
  pieChartData.push(noValueEmployeeData)

  return pieChartData
}

export const groupBy = (by: string, records: Record[]): GroupedRecord => {
  return records.reduce((acc: GroupedRecord, record: Record) => {
    const key: string | number = record[by as keyof Record]
    acc[key] = acc[key] || []
    acc[key].push(record)
    return acc
  }, {} as GroupedRecord)
}

export const groupAgesBy = (records: AgeGraphData[]): GroupedAgeRecord => {
  return records.reduce((acc: GroupedAgeRecord, record: AgeGraphData) => {
    const key: string | number = record['ageGroup' as keyof AgeGraphData]
    acc[key] = acc[key] || []
    acc[key].push(record)
    return acc
  }, {} as GroupedAgeRecord)
}

export const getDepartmentStackedData = (
  data: Record[],
  propertyToGroupBy: string,
  byDepartment: boolean = false
): BarGraphData => {
  const series: BarGraphDataPoint[] = [] as BarGraphDataPoint[]

  // First get the array of propertyToGroupBy values
  const groupedData: GroupedRecord = groupBy(propertyToGroupBy, data)
  const groupedDepartments: GroupedRecord = groupBy('department', data)
  const distributeByKeys: string[] = byDepartment
    ? Object.keys(groupedDepartments)
    : Object.keys(groupedData)

  // If byDepartment, use the groupedData for the stacked bar chart
  if (byDepartment) {
    const properties: string[] = Object.keys(groupedData)

    // Cycle through the propertyToGroupByKeys array and get the grouped data for each department
    properties.forEach(property => {
      const propertyData: number[] = [] as number[]
      const propertyGroupsByDepartment: GroupedRecord = groupBy(
        'department',
        groupedData[property]
      )
      // construct the propertyData array based on each propertyToGroupByKeys
      distributeByKeys.forEach((key: string, index: number) => {
        propertyData[index] = propertyGroupsByDepartment[key]
          ? propertyGroupsByDepartment[key].length
          : 0
      })
      // construct the BarGraphDataPoint object and add it to the series
      const barGraphDataPoint: BarGraphDataPoint = {
        data: propertyData,
        id: property,
        stack: 'propertyDistribution',
        name: property,
        label: property
      }
      series.push(barGraphDataPoint)
    })
  } else {
    const departments: string[] = Object.keys(groupedDepartments)

    // Cycle through the propertyToGroupByKeys array and get the grouped data for each department
    departments.forEach(department => {
      const propertyData: number[] = [] as number[]
      const propertyGroupsByDepartment: GroupedRecord = groupBy(
        propertyToGroupBy,
        groupedDepartments[department]
      )
      // construct the propertyData array based on each propertyToGroupByKeys
      distributeByKeys.forEach((key: string, index: number) => {
        propertyData[index] = propertyGroupsByDepartment[key]
          ? propertyGroupsByDepartment[key].length
          : 0
      })
      // construct the BarGraphDataPoint object and add it to the series
      const barGraphDataPoint: BarGraphDataPoint = {
        data: propertyData,
        id: department,
        stack: 'departmentDistribution',
        name: department,
        label: department
      }
      series.push(barGraphDataPoint)
    })
  }

  // Reformat the propertyToGroupByKeys to simplify the labels
  distributeByKeys.forEach((label: string, index: number) => {
    if (label === 'Asian or Asian American') distributeByKeys[index] = 'Asian'
    if (label === 'Hispanic or Latino') distributeByKeys[index] = 'Latine'
  })

  const titleLabel =
    propertyToGroupBy.charAt(0).toUpperCase() + propertyToGroupBy.slice(1)

  const title = `${titleLabel} By Department`

  // Return BarGraphData object
  return {
    series,
    xLabels: distributeByKeys,
    title
  } as BarGraphData
}

const formatAgeGroupLabel = (ageGroup: string): string => {
  if (
    ageGroup === '0 - 20 YRS' ||
    ageGroup === '20 - 24 YRS' ||
    ageGroup === '25 - 29 YRS'
  ) {
    return '20s'
  }
  if (ageGroup === '30 - 34 YRS' || ageGroup === '35 - 39 YRS') {
    return '30s'
  }
  if (ageGroup === '40 - 44 YRS' || ageGroup === '45 - 49 YRS') {
    return '40s'
  }
  if (ageGroup === '50 - 54 YRS' || ageGroup === '55 - 59 YRS') {
    return '50s'
  }
  if (ageGroup === '60+ YRS') {
    return '60+'
  }
  if (ageGroup === 'No Value') {
    return 'NA'
  }
  return ageGroup
}

const createFinalAgeGraphData = (data: AgeGraphData[]): AgeGraphData[] => {
  // group all objects by the new ageGroup label
  const groupedAgeRecords: GroupedAgeRecord = groupAgesBy(data)
  const ageKeys: string[] = Object.keys(groupedAgeRecords)

  // Construct the new AgeGraphData array
  const formattedArray: AgeGraphData[] = [] as AgeGraphData[]
  const ageCountsArray: number[] = [] as number[]
  const percentageCountsArray: number[] = [] as number[]
  const formattedAgeKeys: string[] = [] as string[]

  // correct the order of ageKeys based on age group
  ageKeys.forEach((key: string) => {
    const ageRange: number =
      key && key !== 'NA' ? Number(key.replace(/s|\+/g, '')) : -1
    if (ageRange === 20) {
      formattedAgeKeys[0] = key
    }
    if (ageRange === 30) {
      formattedAgeKeys[1] = key
    }
    if (ageRange === 40) {
      formattedAgeKeys[2] = key
    }
    if (ageRange === 50) {
      formattedAgeKeys[3] = key
    }
    if (ageRange === 60) {
      formattedAgeKeys[4] = key
    }
    if (ageRange === -1) {
      formattedAgeKeys[5] = key
    }
  })

  // Get the new sum of each new ageGroup
  formattedAgeKeys.forEach((key: string, index: number) => {
    let ageCount: number = 0
    groupedAgeRecords[key].forEach((record: AgeGraphData) => {
      ageCount += record.count
    })
    ageCountsArray[index] = ageCount
  })

  // sum all age groups
  const sumOfAges: number = ageCountsArray.reduce(
    (acc: number, curr: number) => {
      return acc + curr
    },
    0
  )

  // calculate the percentage of each ageGroup and save to an array to use later
  ageCountsArray.forEach((ageCount: number, index: number) => {
    const percentofTotal: number = Math.round((ageCount / sumOfAges) * 100)
    percentageCountsArray[index] = percentofTotal
  })

  // construct the AgeGraphData array
  formattedAgeKeys.forEach((key: string, index: number) => {
    const template: AgeGraphData = {
      count: ageCountsArray[index],
      ageGroup: formatAgeGroupLabel(key),
      percentage: percentageCountsArray[index]
    }
    formattedArray.push(template)
  })

  return formattedArray
}

export const getAgeBarData = (data: Record[]): AgeGraphData[] => {
  const ageGraphDataArray: AgeGraphData[] = [] as AgeGraphData[]
  const ageCountsArray: number[] = [] as number[]

  // get GroupedRecord object grouped by ageGroup arrays
  const groupedAgeRecords: GroupedRecord = groupBy('ageGroup', data)

  // get keys for each ageGroup
  const groupedAgeKeys: string[] = Object.keys(groupedAgeRecords)

  // for each key get the array length for the ageGroup and store in an array to get the total sum
  groupedAgeKeys.forEach((key: string, index: number) => {
    const ageCount: number = groupedAgeRecords[key]
      ? groupedAgeRecords[key].length
      : 0
    ageCountsArray[index] = ageCount
  })

  // construct initial AgeGraphData array with the new formatted ageGroup labels
  groupedAgeKeys.forEach((key: string, index: number) => {
    const ageGraphData: AgeGraphData = {
      count: ageCountsArray[index],
      ageGroup: formatAgeGroupLabel(key),
      percentage: 0
    }
    ageGraphDataArray.push(ageGraphData)
  })

  // get the final AgeGraphData array
  const finalDataToSend: AgeGraphData[] =
    createFinalAgeGraphData(ageGraphDataArray)

  return finalDataToSend
}
