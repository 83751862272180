import React from 'react'
import { Box, Typography } from '@mui/material'
import { SalesCardType } from 'models'
import HrisButton from 'components/HrisButton'

interface IProps {
  data: SalesCardType
  epName: string
}

const HrisCard = ({ data, epName }: IProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #E5E5E5',
        padding: '32px 52px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff'
      }}
    >
      <Box sx={{ width: '59.2%' }}>
        <Typography
          sx={{
            fontWeight: 700,
            lineHeight: '40px',
            fontSize: '36px',
            letterSpacing: '-0.72px',
            marginBottom: '24px'
          }}
        >
          Transform your DEI strategy with <br /> inclusive analytics
        </Typography>

        <Typography>
          Seamlessly integrate your HRIS to proactively identify gaps and
          improve <br /> opportunity equity, without needing an analyst. We’ll
          enhance your existing <br />
          people data to better track vital workforce information, all through
          our secure platform.
        </Typography>
        <HrisButton epName={epName} />
      </Box>
      <img
        src={data.url}
        style={{ width: '40%', borderRadius: '8px' }}
        alt='sales card media'
      />
    </Box>
  )
}

export default HrisCard
