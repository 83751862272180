/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/function-component-definition */
import * as React from 'react'
import { BarChart } from '@mui/x-charts'
import { Box, Typography, createTheme } from '@mui/material'
import { BarGraphData } from 'models'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536
    }
  }
})

interface IProps {
  data: BarGraphData
}

const StackedBarChart = ({ data }: IProps) => {
  return (
    <Box
      sx={{
        background: '#FFF',
        padding: '25px',
        border: '1px solid #E5E5E5',
        borderRadius: '0.5rem',
        width: '100%',
        height: 'inherit'
      }}
    >
      <Box sx={{ marginBottom: '20px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'left',
            [theme.breakpoints.down('lg')]: {
              fontSize: '16px'
            }
          }}
        >
          {data.title}
        </Typography>
      </Box>
      <BarChart
        colors={[
          '#5F71E5',
          '#DD66AD',
          '#6DCCCD',
          '#FFC165',
          '#373C3F',
          '#9AE5F3',
          '#8E63DE',
          '#FF9292'
        ]}
        height={300}
        series={data.series}
        xAxis={[
          {
            data: data.xLabels,
            scaleType: 'band',
            tickLabelStyle: { fontWeight: '700' },
            tickFontSize: 12
          }
        ]}
        yAxis={[
          {
            min: 0,
            max: 100,
            scaleType: 'linear',
            disableLine: false
          }
        ]}
        slotProps={{
          legend: {
            hidden: true
          },
          bar: {
            radius: 20
          }
        }}
      />
    </Box>
  )
}
export default StackedBarChart
