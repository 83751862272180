/* eslint-disable no-console */
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { MergeContext } from 'context/MergeContext'
import {
  Box,
  Typography,
  Button,
  ButtonProps,
  Snackbar,
  SnackbarProps,
  Link
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ClearIcon from '@mui/icons-material/Clear'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LoadingCircular from 'components/LoadingCircular'
import { styled } from '@mui/material/styles'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { MicroServiceContext } from 'context/Microservice'

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontFamily: 'IBM Plex Sans',
  lineHeight: '24px',
  color: '#FFFFFF',
  padding: '12px 16px',
  backgroundColor: '#000000',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    color: '#050B0E'
  },
  '&:disabled': {
    backgroundColor: '#F2F3F3',
    color: '#a2a2a2'
  }
}))

interface IProps {
  epName: string
}

const HrisButton = ({ epName }: IProps) => {
  const {
    linkedAccount,
    linkedAccountIsLoading,
    linkToken,
    getLinkToken,
    createLinkedAccount
  } = useContext(MergeContext)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [messageSent, setMessageSent] = useState(false)

  const { currentAccount } = useContext(MicroServiceContext)
  const hrisEnabled: boolean = currentAccount?.role === 'SUPER_MANAGER'
  const slackMessageInfo = {
    id: currentAccount?.id.split('/')[0],
    name: currentAccount?.user.name,
    employer: currentAccount?.employer.name
  }

  useEffect(() => {
    if (!linkedAccount && !linkedAccountIsLoading && epName) {
      getLinkToken({
        variables: {
          organizationName: epName,
          category: 'hris'
        }
      })
    }
  }, [epName, getLinkToken, linkedAccount, linkedAccountIsLoading])

  const onMergeSuccess = useCallback(
    (publicToken: String) => {
      createLinkedAccount({ variables: { publicToken } })
    },
    [createLinkedAccount]
  )

  const handleMergeError = () => {
    setSnackbarOpen(true)
    console.log(
      'HRIS integration was cancelled. There may have been an error or the user may have cancelled the operation.'
    )
  }

  const mergeConfig = {
    linkToken: linkToken?.linkToken,
    onSuccess: (public_token: String) => onMergeSuccess(public_token),
    onExit: () => handleMergeError()
  }

  const { open: openMerge } = useMergeLink(mergeConfig)

  const handleContactMessage = async () => {
    const response = await fetch(`${process.env.REACT_APP_HRIS_ZAPIER_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({ slackMessageInfo })
    })
    const data = await response.json()
    if (data) {
      setMessageSent(true)
      setSnackbarOpen(true)
    }
  }

  if (linkedAccountIsLoading) return <LoadingCircular containerHeight={200} />

  return (
    <Box>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ padding: '12px 17px', marginTop: '75px' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {hrisEnabled
            ? 'HRIS integration was cancelled. Please try again.'
            : 'Your message was sent. We will be in touch shortly.'}
          <ClearIcon
            onClick={() => setSnackbarOpen(false)}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
      </StyledSnackbar>
      {!linkedAccount ? (
        <Box>
          {hrisEnabled ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '24px'
              }}
            >
              <StyledButton onClick={() => openMerge()}>
                Link HRIS
                <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
              </StyledButton>
              <Link
                href='https://mathison.notion.site/Getting-Started-with-Mathison-s-Dashboard-HRIS-Integration-70691446f9594909a31a2b465d0c4a48'
                target='_blank'
                paddingLeft='33px'
                style={{ textDecoration: 'underline' }}
                variant='body1'
              >
                Learn More
              </Link>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '24px'
              }}
            >
              <StyledButton
                disabled={messageSent}
                onClick={() => handleContactMessage()}
              >
                Contact us to connect your HRIS
                <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
              </StyledButton>
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Typography sx={{ padding: '20px 0 10px 0' }}>
            Your HRIS is linked and your data is currently being processed.
            <br /> Please come back soon to view your results.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon sx={{ color: 'green' }} />
            <Typography sx={{ marginLeft: '8px' }}>
              HRIS connected: <b>{linkedAccount.integrationName}</b>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default HrisButton
