import { useContext, useEffect, useState } from 'react'
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { MicroServiceContext } from 'context/Microservice'

const LINKED_ACCOUNT = gql`
  query LinkedAccount($category: String!) {
    linkedAccount(category: $category) {
      id
      integrationName
      contactEmail
    }
  }
`

const LINK_TOKEN = gql`
  query GetMagicLink($organizationName: String!, $category: String!) {
    linkToken(organizationName: $organizationName, category: $category)
  }
`

const CREATE_LINKED_ACCOUNT = gql`
  mutation CreateLinkedAccount($publicToken: String!) {
    createLinkedAccount(publicToken: $publicToken) {
      organizationName
      integrationName
    }
  }
`

const useMerge = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  const [linkedAccountData, setLinkedAccount] = useState<any>(undefined)
  const [linkToken, setLinkToken] = useState<any>(undefined)

  const {
    data,
    loading: linkedAccountIsLoading,
    error: linkedAccountError
  } = useQuery(LINKED_ACCOUNT, {
    client: maestroClient,
    variables: { category: 'hris' }
  })

  const [createLinkedAccount] = useMutation(CREATE_LINKED_ACCOUNT, {
    client: maestroClient,
    variables: { category: 'hris' },
    // update the cache, this is required so changes are displayed immediately
    update(cache: any, { data: { linkedAccount } }: any): void {
      cache.modify({
        fields: {
          linkedAccount(): any {
            return linkedAccount
          }
        }
      })
    }
  })

  const [getLinkToken, { data: mergeLinkToken }] = useLazyQuery(LINK_TOKEN, {
    client: maestroClient
  })

  useEffect(() => {
    if (data) {
      setLinkedAccount(data.linkedAccount)
    }
  }, [data])

  useEffect(() => {
    if (mergeLinkToken) {
      setLinkToken(mergeLinkToken)
    }
  }, [mergeLinkToken])

  return {
    linkedAccountData,
    linkedAccountError,
    linkedAccountIsLoading,
    linkToken,
    getLinkToken,
    createLinkedAccount
  }
}
export default useMerge
