import React, { createContext, Context, useMemo } from 'react'
import useMerge from 'hooks/useMerge'

export type MergeContextType = {
  linkedAccount: any
  linkedAccountError: any
  linkedAccountIsLoading: boolean
  linkToken: any
  createLinkedAccount: (variables?: any) => any
  getLinkToken: (variables?: any) => any
}

export const MergeContext: Context<MergeContextType> =
  createContext<MergeContextType>({
    linkedAccount: undefined,
    linkedAccountError: undefined,
    linkedAccountIsLoading: true,
    linkToken: undefined,
    createLinkedAccount: () => {},
    getLinkToken: () => {}
  })

export const MergeProvider = ({ children }: any): JSX.Element => {
  const {
    linkedAccountData,
    linkedAccountError,
    linkedAccountIsLoading,
    linkToken,
    createLinkedAccount,
    getLinkToken
  } = useMerge()

  const values = useMemo(
    () => ({
      linkedAccount: linkedAccountData,
      linkedAccountError,
      linkedAccountIsLoading,
      linkToken,
      createLinkedAccount,
      getLinkToken
    }),
    [
      linkedAccountData,
      linkedAccountError,
      linkedAccountIsLoading,
      linkToken,
      createLinkedAccount,
      getLinkToken
    ]
  )

  return (
    <MergeContext.Provider value={values}>{children}</MergeContext.Provider>
  )
}
