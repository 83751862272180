import React from 'react'
import { Typography, Box, BoxProps } from '@mui/material'
import styled from '@emotion/styled'
import { SingleStat } from 'models'
import { theme } from 'components'

const StyledSingleStat = styled(Box)<BoxProps>(() => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  border: '1px solid #e5e5e5',
  padding: '15px',
  height: '170px',
  background: '#fff',
  [theme.breakpoints.down(1350)]: {
    height: '140px'
  }
}))

interface IProps {
  data: SingleStat
}

const SingleStatsChart = ({ data }: IProps) => {
  return (
    <StyledSingleStat>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          textAlign: 'left',
          [theme.breakpoints.down(1350)]: {
            fontSize: '16px'
          }
        }}
      >
        {data.title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          placeContent: 'center',
          marginTop: '4%'
        }}
      >
        <Typography
          sx={{ fontSize: '36px', fontWeight: 600, color: '#373C3F' }}
        >
          {data.value}{' '}
        </Typography>
      </Box>
    </StyledSingleStat>
  )
}

export default SingleStatsChart
