/* eslint-disable import/order */
import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import DashboardHome from 'pages'
import { MergeProvider } from 'context/MergeContext'
import { DashboardProvider } from '../context/DashboardContext'

const RouteProvider = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/dashboard') {
      document.title = 'Dashboard - Demographics | Mathison'
    }
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname])
  return (
    <Grid
      sx={{
        maxWidth: 1460,
        width: '90%',
        height: '100vh',
        padding: '40px 0',
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid sx={{ width: '100%', height: '100vh' }}>
        <DashboardProvider>
          <MergeProvider>
            <Routes>
              <Route path='/dashboard' element={<DashboardHome />} />
            </Routes>
          </MergeProvider>
        </DashboardProvider>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
