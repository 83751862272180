const HeroImgOffice =
  'https://www.datocms-assets.com/54533/1690403837-laptopbreak.webp'
const HeroImgPng =
  'https://www.datocms-assets.com/54533/1690403843-laptopbreakfull.webp'

export const HERO = {
  title: 'ERROR 404 • page not found',
  message: 'It looks like this goal walked away.',
  image: `${HeroImgPng}`
}

export const HERO_SERVER_ERROR = {
  title: '',
  message:
    'There seems to be an issue with the server. Please try again later.',
  image: `${HeroImgOffice}`,
  desc: 'Already merged your HRIS? The data may still be loading. Give it some time and check in soon.',
  btnTitle: 'Go to the Organization Settings',
  btnUrl: '/organization-settings',
  linkTitle: 'Link your HRIS',
  linkUrl:
    'https://mathison.notion.site/Mathison-Platform-Guide-3cec3a89018843b0874cbdb3bcc0fb99'
}

export const HERO_NO_HRIS_DATA = {
  title: '',
  message:
    'Integrate your HRIS with Mathison to view a breakdown of your data.',
  image: `${HeroImgOffice}`,
  desc: 'Already merged your HRIS? The data may still be loading. Give it some time and check in soon.',
  btnTitle: 'Go to the Organization Settings',
  btnUrl: '/organization-settings',
  linkTitle: 'Link your HRIS',
  linkUrl:
    'https://mathison.notion.site/Mathison-Platform-Guide-3cec3a89018843b0874cbdb3bcc0fb99'
}
