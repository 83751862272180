import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Typography } from '@mui/material'
import { HERO, HERO_SERVER_ERROR, HERO_NO_HRIS_DATA } from './constants'
import {
  ImageInfo,
  ImageBlock,
  InfoBlock,
  InfoButton
} from './styledComponents'

interface IProps {
  serverError: boolean
  delay: number
}

const ImageInfoBlock = ({ serverError, delay }: IProps) => {
  const serverErrorHeroData = { ...HERO, ...HERO_SERVER_ERROR }
  const hrisErrorHeroData = { ...HERO, ...HERO_NO_HRIS_DATA }

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return (
    <Box>
      {isVisible ? (
        <ImageInfo>
          <ImageBlock url={serverErrorHeroData.image} />
          {serverError ? (
            <InfoBlock>
              {serverErrorHeroData?.title?.length > 0 && (
                <Typography
                  variant='subtitle1'
                  marginBottom={3}
                  sx={{
                    textTransform: 'uppercase'
                  }}
                >
                  {serverErrorHeroData.title}
                </Typography>
              )}
              <Typography variant='h1' marginBottom={3}>
                {serverErrorHeroData.message}
              </Typography>
              <Typography marginBottom={5}>
                {serverErrorHeroData.desc}
              </Typography>
              <Grid container>
                <Grid item xs={7}>
                  <InfoButton
                    href={serverErrorHeroData.btnUrl}
                    variant='contained'
                  >
                    {serverErrorHeroData.btnTitle}
                  </InfoButton>
                </Grid>
              </Grid>
            </InfoBlock>
          ) : (
            <InfoBlock>
              {hrisErrorHeroData?.title?.length > 0 && (
                <Typography
                  variant='subtitle1'
                  marginBottom={3}
                  sx={{
                    textTransform: 'uppercase'
                  }}
                >
                  {hrisErrorHeroData.title}
                </Typography>
              )}
              <Typography variant='h1' marginBottom={3}>
                {hrisErrorHeroData.message}
              </Typography>
              <Typography variant='body1' marginBottom={5}>
                {hrisErrorHeroData.desc}
              </Typography>
              <Grid container>
                <Grid item xs={7}>
                  <InfoButton
                    href={hrisErrorHeroData.btnUrl}
                    variant='contained'
                  >
                    {hrisErrorHeroData.btnTitle}
                  </InfoButton>
                </Grid>
              </Grid>
            </InfoBlock>
          )}
        </ImageInfo>
      ) : null}
    </Box>
  )
}

export default ImageInfoBlock
