import { Operator, VizzlyAccessToken } from 'models'
import { DataSet } from '@vizzly/dashboard'

export const ALLOWED_OPERATORS: Operator[] = [
  '=',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  'is_one_of',
  'is_not_one_of'
]

export const HRIS_DATA_SET_V1: DataSet = {
  id: 'hris_v_1',
  name: 'HRIS Data v1',
  fields: [
    {
      dataType: 'string' as const,
      publicName: 'ID',
      id: 'employeeId',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Employment Status',
      id: 'employmentStatus',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Department',
      id: 'department',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'number' as const,
      publicName: 'Age',
      id: 'age',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Age Group',
      id: 'ageGroup',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Ethnicity',
      id: 'ethnicity',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Gender',
      id: 'gender',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'number' as const,
      publicName: 'Tenure',
      id: 'tenureYears',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Veteran Status',
      id: 'veteranStatus',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Disabled Status',
      id: 'disabledStatus',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'HRIS Integration',
      id: 'integration',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    },
    {
      dataType: 'string' as const,
      publicName: 'Group Type',
      id: 'groupType',
      canBeDimension: true,
      canBeMeasure: true,
      allowedOperators: ALLOWED_OPERATORS
    }
  ]
}

// ADMIN ACCESS TOKEN
export const ADMIN_ACCESS: VizzlyAccessToken = {
  accessTokens: {
    dashboardAccessToken:
      process.env.REACT_APP_VIZZLY_DASHBOARD_ACCESS_TOKEN || ''
  }
}

// ADMIN DASHBOARD ACCESS TOKEN
export const ADMIN_DASHBOARD_ACCESS: VizzlyAccessToken = {
  accessTokens: {
    dashboardAccessToken:
      process.env.REACT_APP_VIZZLY_DEPARTMENTS_DASHBOARD_TOKEN || ''
  }
}

// READ-ONLY ACCESS TOKEN
export const READ_ONLY_ACCESS: VizzlyAccessToken = {
  accessTokens: {
    dashboardAccessToken:
      process.env.REACT_APP_VIZZLY_EP_DASHBOARD_ACCESS_TOKEN || ''
  }
}

// READ-ONLY DASHBOARD ACCESS TOKEN
export const READ_ONLY_DASHBOARD_ACCESS: VizzlyAccessToken = {
  accessTokens: {
    dashboardAccessToken:
      process.env.REACT_APP_VIZZLY_EP_DEPARTMENTS_DASHBOARD_TOKEN || ''
  }
}
