import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import { MicroServiceProvider } from 'context/Microservice'
import { MicroServices } from 'models/microservice'
import App from './App'

interface Props {
  container?: any
}
let root: Root
const render = (props: MicroServices & Props) => {
  const {
    container,
    maestroClient,
    currentAccount,
    isFlagEnabled,
    route,
    displayNotification
  } = props
  root = createRoot(
    container
      ? container.querySelector('#pulsar-root')
      : document.getElementById('pulsar-root')
  )
  root.render(
    <React.StrictMode>
      <MicroServiceProvider
        maestroClient={maestroClient}
        currentAccount={currentAccount}
        isFlagEnabled={isFlagEnabled}
        route={route}
        displayNotification={displayNotification}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </MicroServiceProvider>
    </React.StrictMode>
  )
}

export const bootstrap = async () => {}

export const mount = async (props: MicroServices) => {
  render(props)
}

export const unmount = async () => {
  root.unmount()
}

export const update = async (props: any) => {
  render(props)
}

// eslint-disable-next-line no-underscore-dangle
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({
    children: undefined,
    maestroClient: undefined,
    currentAccount: {
      id: '',
      employer: { name: '', code: '' },
      user: { id: '', name: '', primaryEmail: '' },
      role: ''
    },
    isFlagEnabled: () => undefined,
    route: { to: undefined, from: undefined },
    displayNotification: () => {}
  })
}
