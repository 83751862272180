import React, { useState, useEffect } from 'react'
import { MOCK_DATA_V1 } from 'data'
import { Box, Grid, Typography, Alert, Stack } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { HrisData, Record } from 'models'
import { formatData } from 'hooks/dataAggregation'
import { SALES_CARD_DATA } from 'components/SurveyCard/constants'
import CustomGraphs from 'pages/CustomGraphs'
import HrisCard from './HrisCard'
import VizzlySalesGraphs from './VizzlySalesGraphs'

interface IProps {
  delay: number
  epName: string
  enableVizzlyGraphs: boolean
}

const SalesPage = ({ delay, epName, enableVizzlyGraphs }: IProps) => {
  const [hrisUiData, setHrisUiData] = useState<Record[]>([])

  useEffect(() => {
    const mockData: HrisData = formatData(MOCK_DATA_V1)
    setHrisUiData(mockData.employees)
  }, [])

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return (
    <Box>
      {isVisible ? (
        <>
          <Box sx={{ padding: '40px 0 60px 0' }}>
            <HrisCard data={SALES_CARD_DATA} epName={epName} />
          </Box>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            <Box sx={{ paddingBottom: '40px' }}>
              <Typography variant='h1' marginBottom='24px'>
                Dashboard Preview
              </Typography>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert icon={<InfoIcon fontSize='inherit' />} severity='info'>
                  The following is sample data. Ask your admin to connect your
                  HRIS to view your org&lsquo;s data.
                </Alert>
              </Stack>
            </Box>
          </Grid>
          {enableVizzlyGraphs ? (
            <Box>
              <VizzlySalesGraphs hrisUiData={hrisUiData} />
            </Box>
          ) : (
            <Box>
              <CustomGraphs hrisUiData={hrisUiData} />
            </Box>
          )}
        </>
      ) : null}
    </Box>
  )
}
export default SalesPage
