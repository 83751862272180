/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Box, BoxProps, useMediaQuery } from '@mui/material'
import Vizzly, { DataSet } from '@vizzly/dashboard'
import { READ_ONLY_ACCESS, HRIS_DATA_SET_V1 } from 'config'
import { theme } from '@mathison-inc/components'
import { styled } from '@mui/material/styles'
import { Record } from 'models'

const StyledBox = styled(Box)<BoxProps>(() => ({
  '& [data-themeapi="forms.select"]': {
    padding: '10px 5px 10px 12px'
  }
}))

interface IProps {
  hrisUiData: Record[]
}

const VizzlySalesGraphs = ({ hrisUiData }: IProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200))

  return (
    <Box>
      {isSmallScreen ? (
        <StyledBox id='employee-representation'>
          <Vizzly.Dashboard
            theme={{
              rowLimit: 6,
              detail: 'minimal',
              colors: [
                '#5F71E5',
                '#DD66AD',
                '#6DCCCD',
                '#FFC165',
                '#373C3F',
                '#9AE5F3',
                '#8E63DE',
                '#FF9292'
              ],
              forms: {
                select: {
                  background: '#F7F7F7',
                  borderRadius: '8px',
                  border: '1px solid #E5E5E5',
                  fontSize: '16px',
                  boxShadow: 'inset 0 0 0 1px  transparent',
                  color: '#050B0E',
                  fontWeight: 'bold',
                  '&:hover': {
                    border: '1px solid #E5E5E5'
                  }
                }
              },
              panels: {
                padding: '25px',
                border: '1px solid #E5E5E5',
                boxShadow: 'none'
              },
              charts: {
                title: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#050B0E',
                  fontFamily: 'IBM Plex Sans'
                },
                labels: {
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: '#050B0E'
                }
              },
              buttons: {
                primary: {
                  padding: '12px 16px',
                  fontSize: '16px'
                }
              }
            }}
            parentDashboardId={
              process.env.REACT_APP_VIZZLY_PARENT_SMALL_DASHBOARD_ID ||
              undefined
            }
            featureToggles={{
              showGlobalFilters: true,
              canEditComponentLibrary: true,
              canEditComponentsOnDashboard: true
            }}
            dataSets={async (): Promise<DataSet[]> => {
              return [HRIS_DATA_SET_V1]
            }}
            data={async () => {
              if (hrisUiData) {
                return hrisUiData
              }
              throw new Error('There was an error fetching the data')
            }}
            identity={async () => {
              return READ_ONLY_ACCESS.accessTokens
            }}
          />
        </StyledBox>
      ) : (
        <StyledBox id='employee-representation'>
          <Vizzly.Dashboard
            theme={{
              rowLimit: 6,
              detail: 'minimal',
              colors: [
                '#5F71E5',
                '#DD66AD',
                '#6DCCCD',
                '#FFC165',
                '#373C3F',
                '#9AE5F3',
                '#8E63DE',
                '#FF9292'
              ],
              forms: {
                select: {
                  background: '#F7F7F7',
                  borderRadius: '8px',
                  border: '1px solid #E5E5E5',
                  fontSize: '16px',
                  boxShadow: 'inset 0 0 0 1px  transparent',
                  color: '#050B0E',
                  fontWeight: 'bold',
                  '&:hover': {
                    border: '1px solid #E5E5E5'
                  }
                }
              },
              panels: {
                padding: '25px',
                border: '1px solid #E5E5E5',
                boxShadow: 'none'
              },
              charts: {
                title: {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#050B0E',
                  fontFamily: 'IBM Plex Sans'
                },
                labels: {
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: '#050B0E'
                }
              },
              buttons: {
                primary: {
                  padding: '12px 16px',
                  fontSize: '16px'
                }
              }
            }}
            parentDashboardId={
              process.env.REACT_APP_VIZZLY_PARENT_DASHBOARD_ID || undefined
            }
            featureToggles={{
              showGlobalFilters: true,
              canEditComponentLibrary: true,
              canEditComponentsOnDashboard: true
            }}
            dataSets={async (): Promise<DataSet[]> => {
              return [HRIS_DATA_SET_V1]
            }}
            data={async () => {
              if (hrisUiData) {
                return hrisUiData
              }
              throw new Error('There was an error fetching the data')
            }}
            identity={async () => {
              return READ_ONLY_ACCESS.accessTokens
            }}
          />
        </StyledBox>
      )}
    </Box>
  )
}

export default VizzlySalesGraphs
