/* eslint-disable @typescript-eslint/no-throw-literal */
import React, { useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { MicroServiceContext } from 'context/Microservice'
import RouteProvider from './routes'

const App = () => {
  const { maestroClient } = useContext(MicroServiceContext)

  return (
    <ApolloProvider client={maestroClient}>
      <Router>
        <RouteProvider />
      </Router>
    </ApolloProvider>
  )
}

export default App
