/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Box, Grid, BoxProps, styled, createTheme } from '@mui/material'
import SingleStatsChart from 'components/DashboardCharts/SingleStats'
import HrisPieChart from 'components/DashboardCharts/HrisPieChart'
import StackedBarChart from 'components/DashboardCharts/StackedBarChart'
import {
  Record,
  ActiveEmployees,
  SingleStat,
  PieChartDataPoint,
  BarGraphData
} from 'models'
import {
  getActiveEmployees,
  getAverageAge,
  getAverageTenure,
  getDepartmentStackedData,
  getEthnicityDataForPieChart,
  getGenderIdentityDataForPieChart
} from 'hooks/dataAggregation'
import AgeBarChart from 'components/DashboardCharts/AgeBarChart'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1536
    }
  }
})

const StyledBox = styled(Box)<BoxProps>(() => ({
  marginBottom: '40px',
  '& [data-themeapi="forms.select"]': {
    padding: '10px 5px 10px 12px'
  }
}))

interface IProps {
  hrisUiData: Record[]
}

const CustomGraphs = ({ hrisUiData }: IProps) => {
  const defaultData: ActiveEmployees = {
    dataMap: [] as Record[],
    activeEmployees: { title: '', value: 0 } as SingleStat
  }

  const employees: ActiveEmployees | null = hrisUiData
    ? getActiveEmployees(hrisUiData)
    : null

  // This is the main data for all graphs - active employees
  const dataMap: Record[] = employees?.dataMap || []

  const activeEmployees: SingleStat =
    employees?.activeEmployees || defaultData.activeEmployees

  const averageAge = getAverageAge(dataMap)

  const averageTenure = getAverageTenure(dataMap)

  const ethnicityData: PieChartDataPoint[] =
    getEthnicityDataForPieChart(dataMap)

  const genderData: PieChartDataPoint[] =
    getGenderIdentityDataForPieChart(dataMap)

  // Get Ethnicity BarGraphData
  const ethnicityBarGraphData: BarGraphData = getDepartmentStackedData(
    hrisUiData,
    'ethnicity'
  )

  // Get Gender BarGraphData
  const genderBarGraphData: BarGraphData = getDepartmentStackedData(
    hrisUiData,
    'gender',
    true
  )

  return (
    <Box
      id='employee-representation'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '40px'
      }}
    >
      <Grid
        container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px'
        }}
      >
        <Grid item xs={4} sx={{ paddingRight: '15px' }}>
          <SingleStatsChart data={activeEmployees} />
        </Grid>
        <Grid item xs={4} sx={{ padding: '0 15px' }}>
          <SingleStatsChart data={averageAge} />
        </Grid>
        <Grid item xs={4} sx={{ paddingLeft: '15px' }}>
          <SingleStatsChart data={averageTenure} />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '15px' }}>
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px',
            height: '425px',
            [theme.breakpoints.down('lg')]: {
              height: '100%'
            }
          }}
        >
          <Grid
            item
            sx={{
              paddingRight: '15px',
              height: 'inherit',
              width: '33.333333%',
              [theme.breakpoints.down('lg')]: {
                padding: '0 0 10px 0',
                width: '100%'
              }
            }}
          >
            <HrisPieChart title='Ethnicity' data={ethnicityData} />
          </Grid>
          <Grid
            item
            sx={{
              paddingLeft: '15px',
              height: 'inherit',
              width: '66.666667%',
              [theme.breakpoints.down('lg')]: {
                padding: '10px 0 0 0',
                width: '100%'
              }
            }}
          >
            <StackedBarChart data={ethnicityBarGraphData} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: '30px 0' }}>
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '425px',
            [theme.breakpoints.down('lg')]: {
              height: '100%'
            }
          }}
        >
          <Grid
            item
            sx={{
              paddingRight: '15px',
              height: 'inherit',
              width: '33.333333%',
              [theme.breakpoints.down('lg')]: {
                padding: '0 0 10px 0',
                width: '100%'
              }
            }}
          >
            <HrisPieChart title='Gender Identity' data={genderData} />
          </Grid>
          <Grid
            item
            sx={{
              paddingLeft: '15px',
              height: 'inherit',
              width: '66.666667%',
              [theme.breakpoints.down('lg')]: {
                padding: '10px 0 0 0',
                width: '100%'
              }
            }}
          >
            <StackedBarChart data={genderBarGraphData} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <AgeBarChart data={hrisUiData} />
      </Box>
    </Box>
  )
}
export default CustomGraphs
